import { Link } from 'gatsby'
import React from 'react'
import { Flex, Heading, NavLink, Text } from 'theme-ui'
import SEO from '../components/SEO'

const NotFoundPage = () => (
  <>
    <SEO title="¯\_(ツ)_/¯" titleTemplate="%s" />

    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center',
      }}
    >
      <Heading fontSize="4" mb={4}>
        ¯\_(ツ)_/¯
      </Heading>

      <Heading as="h1" fontSize="5">
        Nothing exists here... the sadness.
      </Heading>

      <Text mt={5}>
        <NavLink as={Link} to="/">
          Go Back
        </NavLink>
      </Text>
    </Flex>
  </>
)

export default NotFoundPage
